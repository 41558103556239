.about-main-container {
  margin-top: 40px;
  strong {
    color: #00ffff;
  }
}

.about-title {
  position: relative;
  margin-bottom: 25px;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }
}

.about-body {
  margin-bottom: 3rem;

  .about-image {
    width: 80%;
    border-radius: 20%;
    object-fit: cover;
  }

  .about-data {
    p {
      margin: 0px;
    }

    .btn {
      color: #000000;
      background-color: #00ffff;
      border: none;
      border-radius: 10px;
      margin-top: 1em;
      margin-bottom: 1em;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-5px);
        color: #000000;
        background-color: #ffd700;
      }
    }
  }

  .about-text {
    font-size: 1.2rem;
  }
}

.skill-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;

  .skill-box {
    // display: flex;
    align-items: center;
    justify-content: center;
    /* center icon horizontally */
    overflow: hidden;
    margin-bottom: 1.5rem;
    padding: 2rem;
    border: 1px solid #00ffff;
    border-radius: 5px;
    transition: all 0.2s ease;

    .lineUp {
      display: block;
      position: absolute;
      // padding: 0.5rem;
      white-space: nowrap;
      font-size: 22px;
      opacity: 1;
      visibility: hidden;
    }

    svg {
      width: 60px;
      height: 60px;
      transition: all 0.2s ease;
    }

    &:hover {
      background-color: #006666;

      svg {
        animation: 1s anim-lineUpIcon ease-out both;
      }

      .lineUp {
        visibility: visible;
        animation: 1s anim-lineUp ease-out forwards;
      }

      @keyframes anim-lineUp {
        0% {
          opacity: 0;
          transform: translateY(0);
        }

        20% {
          opacity: 0;
        }

        50% {
          opacity: 1;
          transform: translateY(100%);
        }

        100% {
          opacity: 1;
          transform: translateY(100%);
        }
      }

      @keyframes anim-lineUpIcon {
        0% {
          transform: translateY(0%);
          scale: 1;
        }

        50% {
          transform: translateY(-20%);
          scale: 0.9;
        }

        100% {
          transform: translateY(-20%);
          scale: 0.9;
        }
      }
    }
  }
}
