.projects-main-container {
  margin-top: 40px;
}

.projects-title {
  position: relative;
  margin-bottom: 25px;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }
}

.project-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0 0 10px #006666;
  background-color: rgb(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  color: white;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 10px #ffd700;
    color: white;
  }

  .project-title {
    color: #00ffff;
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1em;
  }

  .project-description {
    font-size: 1em;
    text-align: center;
    margin-top: 1em;
  }
}

.project-tag-container {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  justify-content: center;
  align-items: center;

  .badge {
    margin: 0.5rem;
  }
}
