.footer {
  margin-top: 100px;
  margin-bottom: 10px;
}

.footer-socials {
  gap: 1rem;
}

.footer-social-icon {
  color: #00ffff;

  &:hover {
    color: #ffd700;
  }
}

.footer-copyright {
  margin-top: 5px;
  color: white;
  gap: 1rem;
}
