.navbar {
  font-size: 1.4rem;
}

.nav-link {
  text-decoration: none !important;
  text-align: center;
  width: 100%;
  transition: color 0.3s, border-bottom-color 0.3s;
  color: #00ffff;

  &:hover {
    color: #ffd700;
  }

  span {
    transition: transform 0.3s;
  }

  &:hover span {
    transform: translateY(-3px);
  }
}
