.experience-main-container {
  margin-top: 40px;
}

.experience-title {
  position: relative;
  margin-bottom: 25px;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }
}

.experience-item {
  background-color: transparent;
  border: none;
  color: white;
  margin-bottom: 40px;

  .experience-item-title {
    margin-left: 10px;
  }

  a {
    text-decoration: none;
    color: #00ffff;
  }

  a:hover {
    color: #ffd700;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding-left: 1em;
    text-indent: -0.7em;
  }

  li::before {
    content: "\002022";
    font-size: 1.1em;
    display: inline-block;
    padding-right: 7px;
    color: #00ffff;
  }
}

.experience-image {
  width: 60px;
  height: 60px;
  border-radius: 80%;
  object-fit: cover;
}
