.home-main-container {
  strong {
    color: #00ffff;
  }
}

.home-title {
  margin-bottom: 100px;
  h1 {
    font-size: 4rem;
    font-weight: 700;
    margin-top: 50px;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.4rem;
  }
}

.home-item {
  margin-top: 100px;
  max-width: 1000px;
  margin: auto;
}

.home-experience-title {
  max-width: 600px;
  margin-top: 50px;
  margin-bottom: 1rem;

  a {
    text-decoration: none;
    color: #00ffff;
  }

  a:hover {
    color: #ffd700;
  }
}

.home-projects-title {
  margin-top: 50px;
  margin-bottom: 1rem;

  a {
    text-decoration: none;
    color: #00ffff;
  }

  a:hover {
    color: #ffd700;
  }
}
